@font-face {
  font-family: 'icomoon';
  src:  url('/assets/icon/icomoon.eot?1egyyc');
  src:  url('/assets/icon/icomoon.eot?1egyyc#iefix') format('embedded-opentype'),
    url('/assets/icon/icomoon.ttf?1egyyc') format('truetype'),
    url('/assets/icon/icomoon.woff?1egyyc') format('woff'),
    url('/assets/icon/icomoon.svg?1egyyc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-gem:before {
  content: "\e967";
}
.icon-crown:before {
  content: "\e968";
}
.icon-phone:before {
  content: "\e966";
}
.icon-check:before {
  content: "\e963";
}
.icon-unlock-solid:before {
  content: "\e964";
}
.icon-images-solid:before {
  content: "\e965";
}
.icon-video:before {
  content: "\e934";
}
.icon-tv:before {
  content: "\e95c";
}
.icon-spinner:before {
  content: "\e95d";
}
.icon-money-bill-wave:before {
  content: "\e95e";
}
.icon-check-square-regular:before {
  content: "\e95f";
}
.icon-check-square:before {
  content: "\e960";
}
.icon-clock-regular:before {
  content: "\e961";
}
.icon-compass:before {
  content: "\e962";
}
.icon-linkedin:before {
  content: "\e948";
}
.icon-instagram:before {
  content: "\e949";
}
.icon-bullhorn:before {
  content: "\e94a";
}
.icon-camera:before {
  content: "\e94b";
}
.icon-circle-notch-solid:before {
  content: "\e94c";
}
.icon-circle-regular:before {
  content: "\e94d";
}
.icon-circle-solid:before {
  content: "\e94e";
}
.icon-dot-circle-regular:before {
  content: "\e94f";
}
.icon-dot-circle-solid:before {
  content: "\e950";
}
.icon-download:before {
  content: "\e951";
}
.icon-draw-polygon:before {
  content: "\e952";
}
.icon-file-alt:before {
  content: "\e953";
}
.icon-file-pdf-regular:before {
  content: "\e954";
}
.icon-history:before {
  content: "\e955";
}
.icon-language:before {
  content: "\e956";
}
.icon-pen:before {
  content: "\e957";
}
.icon-square-regular:before {
  content: "\e958";
}
.icon-times:before {
  content: "\e959";
}
.icon-trash-alt-solid:before {
  content: "\e95a";
}
.icon-undo-solid:before {
  content: "\e95b";
}
.icon-exclamation-circle:before {
  content: "\e93d";
}
.icon-briefcase:before {
  content: "\e93e";
}
.icon-flag-regular:before {
  content: "\e93f";
}
.icon-medal:before {
  content: "\e940";
}
.icon-times-solid:before {
  content: "\e941";
}
.icon-whatsapp:before {
  content: "\e942";
}
.icon-share-square:before {
  content: "\e943";
}
.icon-draw-polygon-solid:before {
  content: "\e944";
}
.icon-eye-slash:before {
  content: "\e945";
}
.icon-eye:before {
  content: "\e946";
}
.icon-user-solid:before {
  content: "\e947";
}
.icon-utensils:before {
  content: "\e900";
}
.icon-hard-hat:before {
  content: "\e902";
}
.icon-tree:before {
  content: "\e903";
}
.icon-car:before {
  content: "\e904";
}
.icon-door-closed:before {
  content: "\e905";
}
.icon-fire-alt:before {
  content: "\e906";
}
.icon-user-tie:before {
  content: "\e907";
}
.icon-bath:before {
  content: "\e908";
}
.icon-book:before {
  content: "\e909";
}
.icon-building:before {
  content: "\e90a";
}
.icon-check-circle:before {
  content: "\e90b";
}
.icon-chevron-down:before {
  content: "\e90c";
}
.icon-chevron-left:before {
  content: "\e90d";
}
.icon-chevron-right:before {
  content: "\e90e";
}
.icon-chevron-up:before {
  content: "\e90f";
}
.icon-comment:before {
  content: "\e910";
}
.icon-edit:before {
  content: "\e911";
}
.icon-envelope:before {
  content: "\e912";
}
.icon-euro-sign:before {
  content: "\e913";
}
.icon-exclamation-triangle:before {
  content: "\e914";
}
.icon-facebook-brands:before {
  content: "\e915";
}
.icon-facebook-f-brands:before {
  content: "\e916";
}
.icon-facebook-square-brands:before {
  content: "\e917";
}
.icon-file-contract:before {
  content: "\e918";
}
.icon-file-regular:before {
  content: "\e919";
}
.icon-filter:before {
  content: "\e91a";
}
.icon-gavel:before {
  content: "\e91b";
}
.icon-globe-solid:before {
  content: "\e91c";
}
.icon-heart:before {
  content: "\e91d";
}
.icon-heart-saved:before {
  content: "\e91e";
}
.icon-home:before {
  content: "\e91f";
}
.icon-map-marker-alt:before {
  content: "\e920";
}
.icon-map-marker:before {
  content: "\e921";
}
.icon-map-pin:before {
  content: "\e922";
}
.icon-map-regular:before {
  content: "\e923";
}
.icon-newspaper:before {
  content: "\e924";
}
.icon-people-carry:before {
  content: "\e925";
}
.icon-print:before {
  content: "\e926";
}
.icon-question-circle:before {
  content: "\e927";
}
.icon-ruler-combined:before {
  content: "\e928";
}
.icon-search-minus:before {
  content: "\e929";
}
.icon-search-plus:before {
  content: "\e92a";
}
.icon-search:before {
  content: "\e92b";
}
.icon-sign-out-alt:before {
  content: "\e92c";
}
.icon-sort-amount-down:before {
  content: "\e92d";
}
.icon-sort-amount-up-solid:before {
  content: "\e92e";
}
.icon-star-regular:before {
  content: "\e92f";
}
.icon-star:before {
  content: "\e930";
}
.icon-stream:before {
  content: "\e931";
}
.icon-street-view:before {
  content: "\e932";
}
.icon-th-large:before {
  content: "\e933";
}
.icon-thumbs-up:before {
  content: "\e935";
}
.icon-thumbs-up-liked:before {
  content: "\e936";
}
.icon-thumbtack-solid:before {
  content: "\e937";
}
.icon-twitter-brands:before {
  content: "\e938";
}
.icon-twitter-square-brands:before {
  content: "\e939";
}
.icon-user-check:before {
  content: "\e93a";
}
.icon-user-circle:before {
  content: "\e93b";
}
.icon-youtube-brands:before {
  content: "\e93c";
}
.icon-bed:before {
  content: "\e901";
}
