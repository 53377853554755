$primaryColor: #166FC1;
$primaryColorHover: #205382;
$primaryColorClick: #1c6cb5;
$secondaryColor: #F57C14;
$lightBlue: #F7F9FC;
$darkBlue: #454f57;
$grayLight:#F7F8F9;
$grayMedium:rgba(172, 176, 173, 0.3);
$grayText:rgba(145, 146, 145, 0.8);
$whiteColor:#FFFFFF;

$red: #B20932;

$bgLogoBlue:#0E3269;
$bgLogoGreen: #65C67A;
$bgLogoRed:#CB4438;

$varYellow:#F7C343;

$transition: all 0.3s ease-out;
