/* You can add global styles to this file, and also import other style files */
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

@import 'assets/styles/variables';
// Icons as fonts
@import 'assets/styles/_icon.scss';
// Theme style
@import 'assets/css/style.css';

@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css";

@import "../node_modules/swiper/swiper-bundle.css";


html {
  overflow-x: hidden;
}

sup {
  font-size: 11px;
}

.font-size-19 {
  font-size: 19px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
.btn,
.btn:focus,
.btn:active,
.btn:active:focus,
.btn:hover,
button,
button:focus,
button:active,
button:hover,
a,
a:hover,
a:focus,
a:active,
a:active:focus {
  outline: none !important;
}

.btn {
  transition: .4s;
}

.btn:active,
.btn:focus,
.btn:active:focus {
  box-shadow: none !important;
}

.btn-primary:focus,
.btn-primary:hover,
.btn-primary.active,
.btn-primary:active {
  color: #fff !important;
  background-color: $primaryColorHover !important;
  border-color: $primaryColorHover !important;
}

.hover-underline:hover {
  text-decoration: underline;
}


// GLOBAL CLASS TEXT WEIGHT
.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.textOneLine {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;

  &--twoLine {
    max-width: 100%;
    -webkit-line-clamp: 2 !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

input.redBorder,
textarea.redBorder {
  border-color: $red !important;
}

label.textRed {
  color: $red !important;
}

.textCapitalize {
  text-transform: capitalize !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-underline {
  text-decoration: underline !important;
}

::-webkit-input-placeholder {
  color: #697A86;
}

::-moz-input-placeholder {
  color: #697A86;
}

::-ms-input-placeholder {
  color: #697A86;
}

// GLOBAL STYLE, CENTER ITEM
.atCenter {
  margin: 0 auto !important;
}

// GLOBAL STYLE COLOR
.colorPrimary {
  color: #166FC1 !important;
}

.orangeColor {
  color: #BE5800 !important;
}

.whiteColor {
  color: white !important;
}

.greenColor {
  color: #65C67A !important;
}

.redColor {
  color: #B20932 !important;
}

.yellowColor {
  color: #F7C343 !important;
}

// AGGIUNGI AI PREFERITI
.starCont {
  width: 30px;
  height: 30px;
  background-color: $lightBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s;

  i {
    font-size: .8rem;
  }

  i.fas {
    color: $red;
  }

  &:hover {
    background-color: $red !important;
    color: #fff;

    i.fas,
    i.heart,
    i.icon-heart,
    i.icon-heart-saved {
      color: #fff;
    }
  }

  &--likes {
    width: auto;

    &:hover {
      color: #515151 !important;
      background-color: $lightBlue !important;
    }
  }
}

.starCont__delete {
  &:hover {
    background-color: $red !important;
    color: #fff !important;
  }

  i.fas:hover {
    color: white !important;
  }
}

@media (max-width: 768px) {
  .text-center-mobile {
    text-align: center !important;
  }
}

@media (max-width: 991px) {
  .banner.banner-1 {
    z-index: auto !important;
  }
}

// ****************************************************
// ****************************************************
// BANNER DI RICERCA NELLE PAGINE: RICERCA-ANNUNCIO, RICERCA-AGENZIA, RICERCA-AGENTE
// ****************************************************
// ****************************************************

.containerSearchBanner {
  border-bottom: 1px solid $grayMedium;
}

.researchResults {
  p {
    color: $primaryColor;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.breadcrumb li a,
.breadcrumb i,
.breadcrumb-item+.breadcrumb-item::before {
  color: #000;
}

.breadcrumb li a:hover {
  text-decoration: underline;
}

.breadcrumb-item.active {
  color: $primaryColor;
}

.searchForm {
  padding: 15px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__select-box {
    width: 25%;
    border: none;
    outline: none;
    border-left: 1px solid $grayMedium;
    padding-left: 20px;
    color: $darkBlue;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .selected {
      width: 100%;
      border-radius: 8px;
      color: $darkBlue;
      position: relative;
      order: 0;
      border: 0;
      outline: none;
      background-color: transparent;
    }

    .option,
    .selected {
      padding: 0;
      cursor: pointer;
    }

    .option:hover {
      background: $lightBlue;
      color: $primaryColor;
    }

    label {
      cursor: pointer;
      margin-bottom: 0;
    }
  }

  &__select {
    width: 25%;
    border: none;
    outline: none;
    border-left: 1px solid $grayMedium;
    background-color: $lightBlue;
    padding: 10px 20px;
    margin-right: 20px;
    color: $darkBlue;
  }

}

.btn-primary__search {
  padding: 10px 30px;
  background-color: $primaryColor;
  border: none;
}

.icon-search-bar {
  font-size: 1.2em !important;
}

//FILTRI DI BARRA DI RICERCA
.select-order {
  border-radius: 8px;
  color: $darkBlue;
  position: relative;
  order: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  padding-right: 35px;

  &:hover {
    color: $primaryColor;
  }
}

// Filters
.filterSelectCont {
  width: 100%;
  background-color: $lightBlue;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  border: 1px solid $grayMedium;

  &:hover {
    color: $primaryColor;
  }

  .filterInputCont {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;

    input {
      width: 50%;
      outline: none;
      border: 1px solid $grayMedium;
      color: $darkBlue;
      padding: 5px 8px;

      &:focus {
        border: 1px solid $primaryColor;
        color: $primaryColor;
      }
    }
  }

  .filterOptionsContainer {
    background-color: white;
    border: 1px solid $primaryColor;
    color: $darkBlue;
    max-height: 0;
    opacity: 0;
    width: 100%;
    transition: all 0.1s;
    overflow: hidden;
    font-size: 1rem;
    font-weight: 400;
    order: 1;
    z-index: 2003;
    position: absolute;
    top: 55px;
    right: 0;

    &--attivo {
      max-height: 240px;
      opacity: 1;
      overflow-y: scroll;
      background-color: #f6f9fc;
      padding: 15px;
      border: 1px solid #d8d8d8;
      border-radius: 2px;
      margin-top: 10px;
    }

    &--attivo+.selectedFilter::after {
      content: "\f106";
    }

    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 2px 5px 5px 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: $darkBlue;
      border-radius: 2px 2px 2px 2px;
    }

    ul {
      padding: 0;
      margin: 0;
      border: none;
    }

    .optionFilter {
      margin-top: 0;
      padding: 10px 0 10px 10px;
      display: flex;
      align-items: center;

      label {
        font-weight: 400;
      }

      &:hover {
        color: $primaryColor;
        text-decoration: underline;
      }
    }

    .radio {
      cursor: pointer;
    }

    .radio {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      //margin-right: 10px;

      &__input {
        display: none;
      }

      &__radio {
        width: 1.4rem;
        height: 1.4rem;
        border: 2px solid #d8e4e2;
        border-radius: 50%;
        margin-right: 10px;
        box-sizing: border-box;
        padding: 4px;
      }

      &__radio::after {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background-color: $primaryColor;
        border-radius: 50%;

        transform: scale(0);
        transition: transform .2s ease;
      }

      &__input:checked+.radio__radio::after {
        transform: scale(1);
      }
    }
  }

  .selectedFilter {
    width: 100%;
    position: relative;
    order: 0;
    font-size: 15px;

    &::after {
      position: absolute;
      content: "\f107";
      right: 20px;
      font-size: 15px;
      font-family: 'Font Awesome 5 Free', sans-serif;
      font-weight: 900;
      transition: all .1s;
    }

    i {
      padding-right: 8px;
    }

  }

}


// ****************************************************
// ****************************************************
//PAGINA DI RICERCA: ANNUNCI, AGENZIA E AGENTE
// ****************************************************
// ****************************************************

.backgroundListingSilver {
  background-color: $lightBlue;
}

.borderBottomSilver {
  border-bottom: 1px solid $grayMedium;
}

.listing-border {
  border-right: 1px solid $grayMedium;
}

.cardFilters {
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $darkBlue;

  i {
    padding-right: 8px;
  }

  &__left {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      color: $primaryColor;
    }
  }

  &__right {
    &:hover {
      color: $primaryColor;
    }
  }
}

.logoWhuis {
  width: 40px;
  height: 40px;
  background-image: url(./assets/img/icona_whuis_resized.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 3px;

  &--small {
    width: 28px !important;
    height: 28px !important;
  }
}

.logoVerified {
  width: 40px;
  height: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 3px;

  &--policy {
    background-image: url(./assets/img/logo_polizza_verificata.webp);
  }

  &--profile {
    height: 30px;
    background-image: url(./assets/img/logo_agenzia_verificata_small.png);
  }

  &--small {
    width: 28px !important;
    height: 28px !important;
  }
}

.starNoReview {
  color: $darkBlue !important;
}

// ENERGY CLASS PAGINA ANNUNCIO + ANNUNCIO STAMPA
.energy-efficiency-graphic {
  span {
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 0 0 #000, 0 1px 0 #000, 1px 1px 0 #000, -1px -1px 0px #000, -1px 0px 0px #000, 0px -1px 0px #000, 1px -1px 0px #000, -1px 1px 0px #000, 0px 0px 0px #000, 0px 0px 0px #000;
    width: 38px;
    height: 25px;
    font-size: 16px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:not(:nth-child(1)) {
      padding-left: 13px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12.5px 0 12.5px 12.5px;
        border-color: transparent currentColor;
      }
    }

    &.disabled {
      opacity: 0.3;
    }

    &:after {
      content: '';
      position: absolute;
      right: -12.5px;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12.5px 0 12.5px 12.5px;
    }


    &.A4 {
      background-color: #758792;
      z-index: 11;

      &:after {
        border-color: transparent #758792;
      }
    }

    &.A3 {
      background-color: #1f8f85;
      z-index: 10;

      &:after {
        border-color: transparent #1f8f85;
      }
    }

    &.A2 {
      background-color: #1e8f6e;
      z-index: 9;

      &:after {
        border-color: transparent #1e8f6e;
      }
    }

    &.A1 {
      background-color: #6ac335;
      z-index: 8;

      &:after {
        border-color: transparent #6ac335;
      }
    }

    &.B {
      background-color: #abdc7d;
      z-index: 7;

      &:after {
        border-color: transparent #abdc7d;
      }
    }

    &.C {
      background-color: #fdfa00;
      z-index: 6;

      &:after {
        border-color: transparent #fdfa00;
      }
    }

    &.D {
      background-color: #fdc800;
      z-index: 5;

      &:after {
        border-color: transparent #fdc800;
      }
    }

    &.E {
      background-color: #f48000;
      z-index: 4;

      &:after {
        border-color: transparent #f48000;
      }
    }

    &.F {
      background-color: #ee4e42;
      z-index: 3;

      &:after {
        border-color: transparent #ee4e42;
      }
    }

    &.G {
      background-color: #eb3d00;
      z-index: 2;

      &:after {
        border-color: transparent #eb3d00;
      }
    }
  }
}

// LOGO CERCACASA X ANNUNCI OLD
.patinaOldLogoCont {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  overflow: hidden;

  .patinaOldLogo {
    width: 65%;
    height: 150px;
    background-image: url(./assets/img/full-logo.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
    opacity: .25;

    &--box {
      width: 90%;
    }
  }
}

@media (max-width: 991px) {

  .listing-border,
  #annunciCont {
    border-right: none !important;
  }
}

@media (max-width: 400px) {
  .logoWhuis {
    width: 30px;
    height: 30px;
  }
}



// PAGINAZIONE
.pagination .page-item.active .page-link {
  background-color: $primaryColor;
  color: #fff;
  border-color: $primaryColor;
}

@media (max-width: 768px) {
  .pagination .page-link {
    font-size: 12px;
    padding: 10px 15px;
    margin-left: 10px;
  }
}

@media (max-width: 550px) {
  .pagination {
    overflow-x: scroll;
  }
}

// ****************************************************
// ****************************************************
//FIXED BAR PAGINE DETTAGLIO AGENZIA AGENTE ANNUNCIO
// ****************************************************
// ****************************************************
.detailInfoFixed {
  width: 100%;
  position: fixed;
  z-index: 2;
  top: -500px;
  transition: 0.1s;

  .container-agenzia-fixed {

    .name,
    p {
      font-size: 12px !important;
    }

    .icon-share-square.text-white {
      font-size: 1.1rem;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .infosCont {

    &__img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      img {
        object-fit: cover;
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      &--rectangular {
        width: 80px !important;
        height: 42px !important;
        border-radius: 0;
        border: none;
        background-size: contain;
        background-position: center;
        position: relative;

        img {
          border-radius: 0;
          object-fit: contain;
        }
      }
    }

    &__agentData {
      p {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  .ctaCont {
    align-items: center !important;
    justify-content: flex-end;

    .btn-custom-2.grey {
      font-size: 14px;
    }
  }

  .ratingFixedBar {

    i {
      font-size: 12px;
      color: $varYellow;
    }

    p {
      font-size: 12px;
    }
  }
}


.infosContImg {
  border-radius: 100%;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  border: 1px solid rgba(172, 176, 173, 0.3);
}

.detailInfoFixed.showDetailBar {
  background-color: #fff;
  top: 58px !important;
  border-bottom: 1px solid #e6e7e6;
  border-top: 1px solid #e6e7e6;
  transition: 0.1s;
  z-index: 1001;
}

@media (max-width: 991px) {
  .detailInfoFixed.showDetailBar {
    top: 70px !important;

    &.isMObile {
      top: 50px !important;
    }

    .infosCont {
      align-items: center;
    }

    .ctaCont {
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    .btn-custom-2--noMobile {
      display: none !important;
    }
  }
}

@media (max-width: 768px) {
  .detailInfoFixed.showDetailBar {
    .ctaCont {
      justify-content: space-between;

      a.btn-custom-2--heart {
        margin-left: 0 !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .detailInfoFixed.showDetailBar {
    .ctaCont {
      margin-top: 5px;

      .btn-custom-2.grey {
        padding: 4px 8px !important;
      }
    }
  }
}

@media (max-width: 550px) {
  .detailInfoFixed.showDetailBar {
    .infosCont {
      justify-content: space-between !important;

      &__img {
        width: 45px;
        height: 45px;

        img {
          width: 45px;
          height: 45px;
        }
      }
    }

    .container-agenzia-fixed p {
      display: none;
    }

    .ctaCont {
      margin-top: 10px;

      .btn-custom-2.grey {
        border: none;
        padding: 0 !important;
      }

      a.btn-custom-2--heart {
        margin-top: 0 !important;
      }
    }
  }
}

@media (max-width: 450px) {
  .detailInfoFixed.showDetailBar {

    a.btn-custom-2.grey,
    .infosCont__agentData p,
    .btn-custom-2--like--mobile {
      font-size: 13px !important;
    }

  }
}

@media (max-width: 350px) {
  .detailInfoFixed.showDetailBar .infosCont__img {
    display: none;
  }
}

// news pagine dettaglio
.similarSection {
  padding-top: 50px;
}

.newsSection {
  padding-top: 50px;
  padding-bottom: 50px;

  h4 {
    margin-bottom: 50px !important;
  }

  &__newsCont-detailPage {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 12px;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 768px) {
    h4 {
      margin-bottom: 24px !important;
    }

    &__newsCont-detailPage {

      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media (max-width: 550px) {
    padding-bottom: 0;
  }
}

// ****************************************************
// ****************************************************
// GALLERY PAGINE DETTAGLIO AGENTE E AGENZIA
// ****************************************************
// ****************************************************

// Gallery
.agencyGalleryImg {
  height: 300px;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;

  &__cont {
    position: relative;
    height: calc(100% - 56px);
    width: 100%;
    border: 1px solid $grayMedium;
    &, & img {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    img {
      object-fit: cover;
    }
  }

  p {
    background-color: #fff;
    color: #000;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 56px;
    border-bottom: 1px solid $grayMedium;
    border-left: 1px solid $grayMedium;
    border-right: 1px solid $grayMedium;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  &__vid-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__vid-preview-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(0px);
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;

    i {
      font-size: 80px;
      color: #fff;
      opacity: 0.7;
      transition: all 0.3s;
    }

    &:hover {
      background-color: rgba(0, 0, 0, .3);
      transition: all 0.3s;

      i {
        opacity: 1;
        transition: all 0.3s;
      }

    }
  }
}

// ****************************************************
// ****************************************************
//LOGO SECTION ANNUNCI, AGENZIA E AGENTE
// ****************************************************
// ****************************************************
.agencyLogoSection-agency {
  padding-top: 100px !important;
}

.handleNoHeaderPadding {
  padding-top: 15px !important;
}

.agencyLogoSection {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .container {
    outline: none;
  }

  &__logoContainer {
    position: relative;
    width: 80px;
    height: 40px !important;

    img {
      object-fit: contain;
      object-position: left;
    }
  }

  &__shareLink {
    float: right;
    text-align: right;
    cursor: pointer !important;
    padding: 0;
    margin: 0;
    color: #fff;
    font-size: 20px;

    a {
      color: white;

      &:hover {
        color: $secondaryColor;
      }
    }

    i {
      margin-left: 10px;
      transition: 0.4s;

      &:hover {
        color: $secondaryColor;
      }
    }
  }

  p {
    color: white;
    font-weight: 500;
  }

  .agencyName {
    position: relative;
    top: 5px;
  }

  .btn-outline-light {
    margin-bottom: 0;
    border-width: 2px;
    font-weight: 400;
  }

  .btn-outline-light:hover {
    color: #000;
  }
}


@media (max-width: 991px) {
  .agencyLogoSection-agency {
    padding-top: 80px !important;
  }
}

@media (max-width: 768px) {
  .btn-outline-light {
    margin-top: 10px;
    margin-bottom: 10px !important;
    width: 100%;
  }
}


/* REMOVE LEAFLET TAG */
//.leaflet-touch .leaflet-control-attribution {
//  display: none;
//}

// MAP CONTAINER
.listing-map-wrapper .listing-map>div {
  z-index: 1;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none !important;
}

/* LEAFLET POPUP */
.leaflet-marker-icon.leaflet-interactive {
  outline: none;
}

.leaflet-container a.leaflet-popup-close-button {
  display: none;
}

.leaflet-popup-content-wrapper {
  padding: 0;

}

.leaflet-popup-content {
  margin: 0 0;
}

.popupInfoCont {
  padding: 5px 10px;
  font-family: "Roboto", sans-serif !important;
}

.logoWhuisPopup {
  width: 30px;
  height: 30px;
  background-image: url(./assets/img/icona_whuis_resized.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  width: 220px !important;
  color: #697A86 !important;
  cursor: pointer;
}

.leaflet-popup-content-wrapper {
  border-radius: 5px !important;
}

.leaflet-popup-content p {
  color: #697A86 !important;
  font-size: .8rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  line-height: 21px;
}

.leaflet-popup-content p:first-of-type {
  color: black !important;
  font-weight: 700;
  text-transform: capitalize !important;
}

.leaflet-popup-content p.price {
  color: #166FC1 !important;
}

.popupImgCont {
  width: 100%;
  height: 110px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./assets/img/placeholders/annuncio.jpg);
}

.popupImgCont img {
  width: 100%;
  height: 110px;
  object-fit: cover;
  margin: auto;
  border-radius: 5px;
}

.iconsPopupCont {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.iconsPopupItem i {
  font-size: .8rem;
}

.iconsPopupItem span {
  font-size: .8rem;
}

// CLUSTER ICONS
.marker-cluster.marker-cluster-small,
.marker-cluster.marker-cluster-medium,
.marker-cluster.marker-cluster-large {
  background-color: white;

  div {
    background-color: #808080;
    transition: .2s ease-out;
    transform: scale(1.1);

    &:hover {
      background-color: rgba(128, 128, 128, .8); //$primaryColorHover;
      transform: scale(1.2);
    }

    span {
      font-weight: 500;
      color: white;
    }
  }

  &.clusterSelectedMarker {
    div {
      background-color: #f57b13 !important;
    }
  }

}

.redtest {
  div {

    background-color: red !important;
  }
}

/*-- CLUSTER POOPUP --*/
.leaflet-popup-content-wrapper {
  width: 300px !important;
  //max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
}

.listClusterContainer {
  flex-direction: column;
}

.popupInfoCont--cluster {
  justify-content: left;
  padding-left: 5px !important;
  padding-right: 5px;
  width: 300px;
  background-color: white;
  padding-top: 7px;
  padding-bottom: 7px;
  border-top: 1px solid rgba(172, 176, 173, 0.3);
  transition: .1s ease-in;
  border-radius: 0;

  &:first-of-type {
    border-radius: 5px 5px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 5px 5px;
  }

  &:hover {
    background-color: rgba(223, 226, 224, 0.3);
    border-top: none;
  }

  p:first-of-type {
    font-size: 12px !important;
    line-height: 18px;
  }

  &:nth-last-of-type(0) {
    padding-top: 5px;
  }

  .clusterImg {
    border-radius: 50%;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    background-size: cover;
    background-position: center;
    background-image: url(./assets/img/placeholders/annuncio.jpg);

    &--annuncio {
      border-radius: 5px;
      min-width: 90px;
      max-width: 90px;
      margin-right: 5px;
      height: auto;
    }

    &--square {
      border-radius: 0;
      height: 25px;
      margin-top: 10px;
    }
  }

  .iconsCont--cluster {
    justify-content: left;
  }

  p.location {
    color: $darkBlue !important;
    font-family: 'Roboto', sans-serif !important;
  }

  .address {
    font-size: .7rem !important;
    max-width: 250px;
  }

  .ratingAndLikes {
    width: 240px;
    justify-content: space-between;
  }

  .agentGridData {
    width: 240px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border: 1px solid $grayMedium;
    margin-top: 5px;
    border-radius: 3px;

    &--professionist {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 5px;
      padding-bottom: 5px;

      &:not(:last-of-type) {
        border-right: 1px solid $grayMedium;
      }

      p {
        font-size: .7rem !important;
      }

      p.orange {
        font-size: 1rem !important;
        color: #F57C14 !important;
      }

      p.green {
        font-size: 1rem !important;
        color: #65C67A !important;
      }
    }
  }
}

.listClusterContainer--agent {
  p.descrPopup {
    font-size: .7rem !important;
    color: $darkBlue !important;
    line-height: 12px;
    margin-top: 5px !important;

    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
}

/*----agency popup----*/
.leaflet-popup-content .agencyPopup i {
  color: #166FC1 !important;
}

.leaflet-popup-content .agencyPopup span {
  font-family: 'Roboto', sans-serif;
  text-transform: capitalize !important;
  font-weight: 200 !important;
  color: #697A86 !important;
}

.leaflet-popup-content .agencyPopup p:last-of-type,
.leaflet-popup-content .agencyPopup p:first-of-type {
  font-size: .8rem !important;
  text-transform: capitalize !important;
}

/* home page controls*/
.leaflet-center {
  z-index: 9999;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1024px) {
  .leaflet-center {
    top: 60%;
  }
}

@media (max-width: 768px) {
  .leaflet-center {
    top: 80%;
  }
}


// OVERRIDE TOASTR CSS
.toast-container .ngx-toastr {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 0 !important;
  box-shadow: -5px 10px 25px rgba(0, 0, 0, 0.15) !important;
  border: 1px solid #e6e7e6 !important;
  transition: 0.6s;
}

.toast-title.ng-star-inserted {
  padding-left: 30px !important;
  font-weight: 500;
}

.toast-container .ngx-toastr {
  padding: 10px 15px !important;
  background-position: 15px 15px !important;
  background-size: 20px !important;
}

.toast-message.ng-star-inserted {
  line-height: 18px !important;
  font-size: 13px !important;
  opacity: 0.6 !important;
  padding-top: 5px !important;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(108,117,125)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E") !important;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(220,53,69)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E") !important;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(40,167,69)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E") !important;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,193,7)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E") !important;
}

.toast-container:hover .ngx-toastr {
  box-shadow: -5px 10px 25px rgba(0, 0, 0, 0.3) !important;
}

// CUSTOMIZE MAPS
.leaflet-div-icon {
  border-color: $primaryColor !important;
  border-radius: 50% !important;
  background-color: #fff !important;
  border-width: 3px !important;
  width: 28px !important;
  height: 28px !important;

  &.firstIcon {
    background-color: $primaryColor !important;
    border-color: #fff !important;
  }
}

#searchBarMap {
  cursor: pointer !important;
}

.leaflet-draw-tooltip.leaflet-draw-tooltip-single {
  display: none !important;
}

// PAGINA UTENTE

.section-user {
  background-color: $lightBlue;
  padding-top: 120px !important;
}

// BREADCRUMB
.sectionBreadcrumb {
  padding-top: 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $grayMedium;
}

// DROPDOWN WITH RANGE
.dropdown-filter-range {
  .dropdown-button {
    width: 100%;
    border: 1px solid $grayMedium;
    border-radius: 2px;
    text-align: left;
    padding: 12px;
    font-size: 14px;
  }


  .dropdown-button::after {
    content: "\e90c";
    font-family: "icomoon", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    border: none;
    position: absolute;
    vertical-align: 0.2em;
    right: 15px;
    //font-size: 14px;
    margin: 1px 0 0;
    color: $darkBlue;
  }

  .dropdown-range {
    z-index: 1;
    background-color: #fff;
    border-bottom: 1px solid $grayMedium;
    border-left: 1px solid $grayMedium;
    border-right: 1px solid $grayMedium;
    position: absolute;
    transform: none !important;
    top: 45px !important;
    left: 0;
    min-width: 100%;
    margin: 0;
    border-radius: 2px;
    padding: 0;
    height: 255px;

    .dropdown-range-input {
      padding: 10px;
      border-bottom: 1px solid $grayMedium;

      input {
        width: calc(50% - 5px);
        height: auto;
        padding: 5px 10px;
        display: inline-block;
        border-radius: 0;

        &:nth-child(1) {
          margin-right: 5px;
        }

        &:nth-child(2) {
          margin-left: 5px;
        }
      }
    }

    .list-unstyled {
      padding: 0;
      margin: 0;
      height: 200px;
      overflow-y: scroll;

      li {
        margin: 0;
        padding: 5px 10px;

        &:hover {
          background-color: $grayLight;
          cursor: pointer;
        }
      }
    }
  }
}


// GESTIONE DEI PIN SULLA MAPPA DI LEAFLET
#ricercaSuMap {
  .leaflet-div-icon {
    width: 20px !important;
    height: 20px !important;

    &:hover {
      width: 24px !important;
      height: 24px !important;
    }

    &.togglePin {
      background-color: #d3eaff !important;
      width: 24px !important;
      height: 24px !important;
    }

  }
}


// STYLE PAGINE STATICHE privacy policy, chi-siamo, contattaci, condizioni d'uso
.sectionBreadcrumb--staticPages {
  background-color: $grayLight;

  p {
    cursor: pointer;
  }
}

.staticPagesText {
  p {
    font-size: 1rem;
  }

  p.orangeColor {
    font-size: 1.2rem;
  }

  ul {
    list-style: disc;
  }

  ul.lower-alpha {
    list-style: lower-alpha;
  }

}

// carousel home
@media (max-width: 768px) {
  .carousel-arrows .carousel-arrow {
    display: none !important;
  }
}

// INPUT, SELECT IOS FIX
input,
select {
  appearance: none;
  outline: none;
}

select {
  background-size: 14px !important;
  background: transparent url("assets/img/chevron.svg") no-repeat right 15px center;
  padding: 5px;

  @media (max-width:550px) {
    font-size: 16px;
  }
}

input[type='checkbox'],
input[type='radio'] {
  width: 18px;
  height: 18px;
}

input[type='checkbox'] {
  border: none;
  position: relative;
  margin-right: 5px;

  //&:before {
  //  content: '';
  //  display: inline-block;
  //  width: 18px;
  //  height: 18px;
  //  border: 1px solid rgb(192, 192, 192);
  //  border-radius: 0.25em;
  //  background: rgb(224, 224, 224);
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //}

  &:checked:before {
    border: 2px solid $primaryColor;
    background-color: $primaryColor;
  }

  &:checked:after {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 0.25em;
    background: transparent url("assets/img/check-white.svg") no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
  }
}

input[type='radio'] {
  border: none;
  margin-top: -5px;

  &:after {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid rgb(192, 192, 192);
    border-radius: 100%;
    background: rgb(224, 224, 224);
  }

  &:checked:after {
    border: 6px solid $primaryColor;
    background-color: #fff;
  }
}

// MOBILE BUTTOM FOOTER PAGINE DETTAGLIO
.mobileButtonMenu {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  border-top: 1px solid rgba(172, 176, 173, 0.3);
  z-index: 1000;

  padding-top: 8px !important;
  padding-bottom: 8px !important;

  &__item {
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      font-size: 12px;
      line-height: 18px;
    }

    i {
      font-size: 21px;
    }

    p,
    i {
      color: $primaryColor;
    }
  }

  &--detail {
    background-color: $primaryColor;
    padding-top: 15px !important;
    padding-bottom: 15px !important;

    &__item {
      flex-direction: row;

      margin-left: 0 !important;
      margin-right: 0 !important;
      //background-color: red;
      width: 100%;

      &--rightBorder {
        border-right: 1px solid white !important;
      }

      p,
      i {
        color: white;
      }

      i {
        font-size: 18px;
      }

      P {
        font-size: 14px;
      }
    }
  }
}

@supports (-webkit-touch-callout: none) {

  /* CSS specific to iOS devices */
  // .mobileButtonMenu {
  //   padding-bottom: 25px !important;
  //      padding-top: 15px !important;

  // }

  .mobileButtonMenu {
    padding:
      env(safe-area-inset-top, 10px) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 10px) env(safe-area-inset-left, 0px) !important;

    a,
    div {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }

    &--detail {
      padding-top: 10px !important;
    }
  }
}

@media (display-mode: standalone) {
  @supports (-webkit-touch-callout: none) {
    .mobileButtonMenu {
      padding-top: 0 !important;
      padding-bottom: 15px !important;

      a,
      div {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
      }

      &--detail {
        padding-top: 10px !important;
        padding-bottom: 30px !important;
      }
    }
  }

  @supports not (-webkit-touch-callout: none) {
    .mobileButtonMenu {
      padding-top: 10px !important;
      padding-bottom: 10px !important;

      a {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      div {
        padding-top: 5px !important;
        padding-bottom: 3px !important;
      }
    }
  }
}

// PAGINE STAMPA
.printPages {
  button {
    background-color: $primaryColor;
    text-align: right;
    border: none;
  }

  .infoAgency {
    p {
      line-height: 21px;
    }

    &__logoCont {
      width: 120px;
      // height: 70px;

      &--circle {
        width: 150px;
        height: 150px;
        border-radius: 100%;
        object-fit: cover;
      }

      &--agency {
        border-radius: 0;
        // width: 100px;
        max-height: 90px;
        object-fit: contain;
      }
    }

    .dxCol {
      width: 80%;
    }

    &__agentData {
      flex-direction: column;
      width: 75%;
      border-bottom: 1px solid rgba(172, 176, 173, 0.3);

      p.font-weight-700 {
        font-size: 16px;
      }

      &__address {
        p {
          font-size: 12px
        }
      }
    }
  }

  .infoTitle {
    h6 {
      font-size: 18px;
    }

    p {
      color: #101737;
      font-size: 16px;
    }
  }

  .imgCont {
    &__item {
      width: 100%;
      height: 250px;

      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .descriptionCont {
    p {
      line-height: 21px;
    }
  }

  /* MAP */
  .listing-map,
  #map {
    width: 100%;
    height: 400px;
    z-index: 100;
  }

  .footerCont {
    p {
      font-size: .8rem;
    }

    .logoCont {
      width: 120px;
    }
  }
}

@media (max-width: 991px) {
  .printPages {
    .infoAgency {
      flex-direction: column;

      &__agentData {
        width: 100% !important;
        margin-top: 20px;
        display: block !important;
      }
    }

    .infoAgency__agentData__agentTel {
      display: block !important;
    }

    .infoTitle {
      display: block !important;
    }

    .dxCol {
      width: 100% !important;
      margin-top: 10px;
      max-width: 100vw;
    }
  }
}

@media(max-width: 768px) {
  .printPages {
    .imgCont {
      &__item {
        margin-left: 0 !important;
        margin-right: 0 !important;

        &:last-of-type {
          margin-left: 15px !important;
        }
      }
    }
  }
}

@media print {
  .pageBreack {
    page-break-before: always;
  }

  .noprint {
    display: none !important;
  }

  .imgCont__item {
    width: 200px;
  }

  .printSection {
    width: 810px;
    height: auto;
    overflow: hidden;

    .listing-map {
      height: 400px;
      width: 810px;
    }
  }

}

// ****************************************************
// ****************************************************
//  TOOLTIP STAR E WHUIS
// ****************************************************
// ****************************************************
.tp {
  padding: 10px;
  border-radius: 5px;
  width: 250px;
  background-color: #444;
  color: white;
  position: absolute;
  top: 40px;
  left: 50px;
  display: inline;
  line-height: 21px;
  z-index: 100;

  span {
    text-decoration: underline;
  }

  &.tp__agenzia_verificata {
    top: unset;
    bottom: -113px;
    font-size: 12px;
    left: -105px !important;

    &::before {
      left: 118px;
    }
  }
}

.tp::before {
  position: absolute;
  left: 15px;
  top: -5px;
  background-color: #444;
  width: 10px;
  height: 10px;
  content: '';
  z-index: 1001;
  transform: rotate(45deg);
  border: 5px solid transparent;
}

@media (max-width: 550px) {

  .stars-outer::before,
  .stars-inner::before {
    font-size: .7rem;
  }
}


// ****************************************************
// ****************************************************
//  CARD SEARCH AGENTE E AGENZIA
// ****************************************************
// ****************************************************
.itemSearchCard {
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  margin-top: 15px;
  border: 1px solid $grayMedium;
  transition: 0.4s;

  &.mapSelected {
    border-color: $primaryColor;
  }

  &:hover {
    border-color: $grayText;
  }


  ////// STYLE LOGHI ///////
  .whuisCertificato {
    background-color: $bgLogoGreen;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;

    p {
      font-size: 12px;
    }
  }

  .logoFiap {
    width: 50px;
    height: 20px;

    img {
      object-fit: cover;
    }
  }

  .logoWhuis {
    width: 32px;
    height: 28px;
    background-position: center;
    background-size: cover;
    background-image: url(./assets/img/loghi_associations/icona_whuis_agent_check.svg);
  }

  ////// FINE STYLE LOGHI ///////

  &__top {
    justify-content: space-between;


    &--left {
      width: 62%;

      img.roundedImage {
        object-fit: cover;
      }

      .roundedImage {
        width: 75px;
        height: 75px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;

        &--agentPlaceholder {
          border: 1px solid $grayMedium;
        }
      }

      .nameAndRole {

        h6 {
          line-height: 18px;
        }

        p {
          color: $darkBlue;
        }

        .agencyName,
        h6 {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .agentAddress {

        p,
        i {
          color: $darkBlue;
          font-size: .8rem;
        }
      }

      .ratingAndLikesCont {

        .agentRating {
          i {
            color: $varYellow;
          }

          p {
            color: #101737;
          }
        }

        .numberOfLike {
          padding: 2px 6px;
          margin: 8px 0;
          border-radius: 3px;
          background-color: #f7f9fc;

          span {
            font-family: 'Roboto', sans-serif;
            color: $darkBlue;
          }

          i {
            color: $red;
          }
        }
      }

    }

    &--right {
      .whuisCertificato {

        p {
          font-size: 12px;
          padding-left: 5px;
        }
      }

    }
  }

  &__middle {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;

    &__agentData {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      border: 1px solid $grayMedium;
      border-radius: 3px;
      width: 50%;


      &__item {
        text-align: center;
        padding-top: 18px;
        padding-bottom: 18px;

        p:first-of-type {
          font-size: 30px;
          line-height: 24px;
          margin-top: 5px;
        }

        p:last-of-type {
          color: $darkBlue;
          font-size: 12px;
          line-height: 18px;
          margin-top: 5px;
        }
      }

      &__item:not(:last-of-type) {
        border-right: 1px solid $grayMedium;
      }
    }

    .decsriptionAndZone {
      width: 50%;
      position: relative;

      &--full-w {
        width: 100%;
        position: initial;
      }

      p.smallDescription {
        line-height: 16px;
        font-size: .8rem;
        color: $darkBlue;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        &--twoDescRow {
          -webkit-line-clamp: 3 !important;
        }
      }

      .zoneServedCont {
        position: absolute;
        bottom: 0;

        &--initial {
          position: initial;
        }
      }
    }

    &__agentZones {
      margin-left: 15px;
      flex-direction: column;
      justify-content: flex-end;

      p:first-of-type {
        color: #101737;
        font-size: 12px;
      }

      .singleZone {
        background-color: $lightBlue;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 5px;

        i,
        p {
          color: $darkBlue;
        }
      }
    }
  }

  &__namingList {
    padding-top: 0;
    padding-bottom: 15px;

    p,
    i {
      color: $darkBlue;
      line-height: 13px;
    }

    .specContainer {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      color: $darkBlue;
    }
  }

  .logoAgencySection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;

    p,
    i {
      color: #ffffff !important;
    }

    &__logo {
      width: 70px;
      height: 20px;

      img {
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 1440px) {
  .itemSearchCard {
    &__top {
      flex-direction: column;

      &--right {
        padding-top: 15px;
        margin-top: 10px !important;
      }

      &--left {
        width: 100%;
      }
    }

    .logoFiap,
    .whuisCertificato {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .logoFiap {
      width: 50px;
      height: 20px;
      margin-right: 10px !important;
    }
  }
}

@media (max-width: 991px) {
  .itemSearchCard {
    &__middle {
      flex-direction: column;
      align-items: flex-start;

      &__agentData {
        width: 100%;
        margin-bottom: 10px;
      }

      .decsriptionAndZone {
        width: 100%;
        position: initial;

        .zoneServedCont {
          position: initial;
        }
      }

      p.smallDescription {
        margin-bottom: 15px !important;
      }

      &__agentZones,
      p.smallDescription {
        margin-left: 0 !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .itemSearchCard__top--left {
    flex-direction: column;

    .nameAndRole {
      padding-top: 15px;
    }

    .pl-3 {
      padding-left: 0 !important;
    }
  }

  .itemSearchCard__top--right {
    //justify-content: space-between !important;
    position: relative;

    .starCont {
      right: 0;
    }
  }
}

@media (max-width: 400px) {
  .itemSearchCard__middle__agentData {

    &__item {
      p:first-of-type {
        font-size: 28px;
        line-height: 24px;
        margin-top: 5px;
      }
    }
  }

  .ratingAndLikesCont {
    display: block !important;

    .numberOfLike {
      margin-top: 10px !important;
      margin-left: 0 !important;
      width: fit-content;
      padding: 2px 10px !important;
    }
  }
}

// STYLE CERCHIO CROP IMAGE IN PAGINA UTENTE MODIFICA
.cropper .move {
  border-color: $primaryColor !important;
}

.cropper .resize .square {
  background: $primaryColor !important;
}

// Privacy e cookie
.privacy-cookie-container {
  .iub_header {
    &:nth-child(1) {

      h1,
      h2 {
        display: none;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0 0 15px;
  }

  h1,
  h2 {
    font-weight: 600;
    font-size: 26px;
  }

  h3,
  h5,
  h6 {
    color: $primaryColor;
    font-weight: normal;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
  }

  .iub_header {
    br {
      display: none;
    }
  }

  .simple_pp {
    margin-top: 40px;
    margin-bottom: 40px;

    h2 {
      font-weight: 400;
      font-size: 17px;

      &:nth-child(1) {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0;
        padding-bottom: 0;
        margin-top: 30px;
      }
    }

    li {
      width: 100%;

      ul:nth-child(1) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        gap: 15px;

        @media (max-width: 550px) {
          grid-template-columns: 1fr;
        }

        h3 {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          padding: 0;
          line-height: 1.5;
        }

        li {
          ul {
            h3 {
              font-weight: 600;
              color: inherit;
              padding-bottom: 5px;
            }
          }
        }
      }
    }
  }

  .one_line_col {
    h2 {
      font-size: 20px;
      font-weight: 600;
    }
  }
}

.agenziaVerificata {
  height: 45px;
  padding: 0 10px;

  @media (max-width:400px) {
    padding: 0;
  }

  img {
    height: 100%;
    width: auto;
  }

  &.small {
    padding: 0 10px;
    height: 27px;
    width: 29px;

    img {
      padding-bottom: 5px;
      object-fit: contain;
    }
  }
}

.page-link {
  padding: 0 !important;
  margin: 0 !important;
  width: 50px !important;
  height: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer;
}

.btn-primary {
  background-color: #166fc1 !important;
  border-color: #166fc1 !important;
}

.text-primary {
  color: #166fc1 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

@media (min-width: 551px) {
  .mobile-menu {
    display: none;
  }
}


// PDF VIEWER STYLE
pdf-viewer {
  display: block;
  border: 1px solid rgba(172, 176, 173, 0.3);
  height: calc(100% - 56px);
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
  overflow: hidden;
  background-color: #eee;
  padding-top: 8px;
}

.pdf-container {
  height: 300px;
  position: relative;
  overflow: hidden;
}


.lazy {
  background: none !important;
}


// NEWS COMMON STYLES
.patinaNews {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgb(34, 34, 34));
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}


// STYILE SLIDERS ANNUNCI, PROFESSIONISTI AGENTI
.swiper-button-prev,
.swiper-button-next {
  top: 40%;

  &::after {
    font-size: 15px;
    padding: 20px;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    color: #000;
    font-weight: bold;
    z-index: 99;
    background-color: #fff;
  }
}

.swiper-button-prev {
  left: 15px !important;
  right: unset !important;
}

.swiper-button-next {
  right: 15px !important;
  left: unset !important;
}

.swiper-button-disabled {
  display: none !important;
}

// STYLE ICONS GLOBAL
.icon-heart-saved {
  color: $red;
}
